import React from "react";
import { useNavigate } from "react-router-dom";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import CustomButton from "../../../atoms/CustomButton";
import moment from "moment/moment";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import {
  counterPartyType,
  GICSectorData,
  statusData,
  transactionType,
} from "../../../../utils/staticData";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../services/downloadService";
import { useSelector } from "react-redux";
import { getData } from "../../../../services";

const ReportsPortfolio = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [counterpartyData, setCounterPartData] = useState([]);
  const [filters, setFilters] = useState({});

  //   const getCounterPartyData = async () => {
  //     try {
  //       const res = await getData({
  //         endpoint: "couterParty/getAllCounters",
  //         token: cookies.t,
  //         params: {
  //           page: currentPage,
  //           limit: itemsPerPage,
  //           search: searchData,
  //           counterType: filters?.["Counterparty Type"],
  //           status: filters?.["Status"],
  //           transactionType: filters?.["Transaction Type"],
  //           sector: filters?.["Sector"],
  //           group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
  //         },
  //       });
  //       setCounterPartData(res?.data);
  //     } catch (err) {
  //       console.log("errr", err);
  //     }
  //   };
  //   useEffect(() => {
  //     getCounterPartyData();
  //   }, [itemsPerPage, currentPage, searchData, filters]);

  const columns = [
    {
      field: "Client",
      headerName: "Client",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.counter_name}</div>
        );
      },
    },
    {
      field: "Obligor",
      headerName: "Obligor",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.counter_type}</div>
        );
      },
    },
    {
      field: "Funder",
      headerName: "Funder",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.trans_type}</div>
        );
      },
    },
    {
      field: "Client Reference",
      headerName: "Client Reference",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {params?.row?.counter_type !== "Client"
              ? params?.row?.clientInfo?.agreement_counter
              : params?.row?.agreement_counter}
          </div>
        );
      },
    },
    {
      field: "Currency",
      headerName: "Currency",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.gics_sector}</div>
        );
      },
    },
    {
      field: "Cost",
      headerName: "Cost",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.counter_name}</div>
        );
      },
    },
    {
      field: "Client",
      headerName: "Client",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.counter_name}</div>
        );
      },
    },
    {
      field: "Face value",
      headerName: "Face value",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params?.row.status === "Active" ? (
              <span className="text-success fw-500">Active</span>
            ) : (
              <span className="text-error fw-500">{params?.row.status}</span>
            )}
          </>
        );
      },
    },
    {
      field: "Funding Date",
      headerName: "Funding Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "Funding Date",
      headerName: "Funding Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "Due Date",
      headerName: "Due Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "Transaction Type",
      headerName: "Transaction Type",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Portfolio created yet!"
          tag="Add Counterparties"
          type="Counterparty"
          classes="d-flex align-items-center"
        />
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const filtersData = {
    "Counterparty Type": counterPartyType,
    "Transaction Type": transactionType,
    Status: statusData,
    Sector: GICSectorData,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "couterParty/export",
      token: cookies.t,
      fileName: "Counterparties",
      params: {
        search: searchData,
        counterType: filters?.["Counterparty Type"],
        status: filters?.["Status"],
        transactionType: filters?.["Transaction Type"],
      },
    });

    if (res) {
      toast.success("Counterparties Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Portfolio</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filtersData}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>

      <CustomDatagrid
        rows={counterpartyData?.[0]?.roleData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {counterpartyData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={counterpartyData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};

export default ReportsPortfolio;
