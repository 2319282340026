import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { getData } from "../../../../../services";
import { Heading } from "../../../../atoms/Heading";
import PageCard from "../../../../atoms/PageCard";
import style from "../index.module.scss";
import { fontSize, width } from "@mui/system";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import moment from "moment";

export const LabelDisplay = ({
  label,
  value,
  className,
  modalStyles = false,
}) => {
  return (
    <div
      className={`d-flex ${className}`}
      style={{ width: modalStyles ? "90%" : "50%" }}
    >
      {label && (
        <label className={style.label_view} style={{ width: "50%" }}>
          {label}
        </label>
      )}
      <div className={style.label_description} style={{ width: "48%" }}>
        {value}
      </div>
    </div>
  );
};

const ViewCounterparty = ({ type = "" }) => {
  const { id } = useParams();
  const [counterData, setCounterData] = useState([]);
  const [cookies] = useCookies(["t"]);
  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setCounterData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id) {
      getCounterDataById();
    }
  }, [id]);

  return (
    <>
      {/* <div
        style={{
          marginBottom: "20px",
          width: "100%",
          borderBottom: "2px solid #D5D5D5",
          paddingBottom: "10px",
        }}
      >
        <span className={style.type_header_styles}>
          {counterData.counter_type}
        </span>
      </div> */}
      <LabelDisplay
        label="Client"
        value={
          counterData.counter_type === "Client"
            ? counterData.counter_name
            : counterData.client_name
        }
        className={style.client_type_main}
      />
      <Heading className="addHeaderStyle fs-20">
        Counterparty Information
      </Heading>
      <div
        className="w-100 d-flex flex-wrap mb-3"
        style={{ columnGap: "0%", rowGap: "10px" }}
      >
        <LabelDisplay label="Name" value={counterData.counter_name} />
        <LabelDisplay label="Address" value={counterData.address} />
        <LabelDisplay label="TaxID" value={counterData.tax_id} />
        <LabelDisplay label="Type" value={counterData.counter_type} />
        <LabelDisplay label="Verified" value={counterData.verified} />
        <LabelDisplay label="Notes" value={counterData.notes} />
      </div>
      <Heading className="addHeaderStyle fs-20">Credit Information</Heading>
      <div
        className="w-100 d-flex flex-wrap mb-3"
        style={{ columnGap: "0%", rowGap: "10px" }}
      >
        <LabelDisplay
          label="Counterparty Type"
          value={counterData.counter_type}
        />
        <LabelDisplay label="Internal Credit Limit" value="--" />
        <LabelDisplay label="Credit Used" value="--" />
        <LabelDisplay label="Credit Remaining" value="--" />
        <LabelDisplay label="Sector" value={counterData.gics_sector} />
        <LabelDisplay label="Industry" value={counterData.gics_industry} />
        <LabelDisplay label="Geography" value={counterData.geography} />
        <LabelDisplay label="Currency" value={counterData.primary_currency} />
      </div>
      {type === "Related Parties Info" && (
        <>
          <Heading className="addHeaderStyle fs-20">
            Insurance Limit Information
          </Heading>
          <div
            className="w-100 d-flex flex-wrap mb-3"
            style={{ columnGap: "0%", rowGap: "10px" }}
          >
            <LabelDisplay label="Limit" value="--" />
            <LabelDisplay label="End Date" value="--" />
            <LabelDisplay label="Type" value="--" />
          </div>

          <Heading className="addHeaderStyle fs-20">
            Related Parties Info
          </Heading>
          <div
            className="w-100 d-flex flex-wrap mb-3"
            style={{ columnGap: "0%", rowGap: "10px" }}
          >
            <LabelDisplay
              label="Obligor"
              value={
                counterData.counter_type === "Obligor"
                  ? counterData.counter_name
                  : "None"
              }
            />
            <LabelDisplay
              label="Supplier"
              value={
                counterData.counter_type === "Supplier"
                  ? counterData.counter_name
                  : "None"
              }
            />
            <LabelDisplay
              label="Signers"
              value={
                counterData &&
                counterData.signerDetails &&
                counterData.signerDetails
                  .map((item, index) => {
                    return (
                      (index !== 0 ? ", " : "") +
                      item.first_name +
                      " " +
                      item.last_name
                    );
                  })
                  .join("")
              }
            />
            <LabelDisplay
              label="Brokers"
              value={
                counterData &&
                counterData.brokerDetails &&
                counterData.brokerDetails
                  .map((item, index) => {
                    return (index !== 0 ? ", " : "") + item.broker_name;
                  })
                  .join("")
              }
            />
          </div>
        </>
      )}
      {type === "Internal Info" && (
        <>
          <Heading className="addHeaderStyle fs-20">Internal Info</Heading>
          <div
            className="w-100 d-flex flex-wrap mb-3"
            style={{ columnGap: "0%", rowGap: "10px" }}
          >
            <LabelDisplay
              label="Agreement Date"
              value={
                counterData.agreement_date
                  ? moment(counterData.agreement_date).format("YYYY-MM-DD")
                  : "--"
              }
            />
            <LabelDisplay label="Financing Entity" value="--" />
            <LabelDisplay label="Template Funding Reference" value={"--"} />
            <LabelDisplay label="S&P Number" value={counterData.sp_number} />
            <LabelDisplay label="D&B Number" value={counterData.db_number} />
            <LabelDisplay
              label="Currency"
              value={counterData.primary_currency}
            />
          </div>
        </>
      )}
    </>
  );
};
export default ViewCounterparty;
