import React, { useState, memo, useEffect, useCallback } from "react";
import CustomButton from "../../../../atoms/CustomButton";
import { Heading } from "../../../../atoms/Heading";
import { useNavigate } from "react-router-dom";
import Search from "../../../../atoms/Search";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import NoContentCard from "../../../../atoms/NoContentCard";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import Pagination from "../../../../atoms/Pagination";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import Edit from "../../../../icons/EditBlue";
import CustomMenu from "../../../../molecules/CustomMenu";
import Reporting from "../../../../icons/Reporting";
import Delete from "../../../../icons/Delete";
import DeleteModal from "../../../../atoms/modals/DeleteModal";
import { deleteData } from "../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
import { convenantFrequency } from "../../../../../helpers/staticData";
import { useSelector } from "react-redux";

const Covenant = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Covenants;
  const [searchData, setSearchData] = useState("");
  const [covenantData, setCovenantData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
    name: "",
  });
  const [filters, setFilters] = useState({});

  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "covenant/delete",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Covenant Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        getCovenantData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const menu = [
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(`edit/${selectedRow?._id}`);
      },
    },
    // {
    //   icon: <Reporting />,
    //   label: "Reporting Requirement",
    //   onClick: () => {
    //     navigate(`reporting-requirement/${selectedRow?._id}`);
    //   },
    // },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.name,
          modalId: selectedRow._id,
        });
        setAnchorEl(null);
      },
    },
  ];

  const getCovenantData = async () => {
    try {
      const res = await getData({
        endpoint: "/covenant",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          frequency: filters?.["Frequency"],
        },
      });
      setCovenantData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getCovenantData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 0.8,
      renderCell: (params) => {
        return <div className="valueStyle">{params?.row?.client_name}</div>;
      },
    },
    {
      field: "name",
      headerName: "Covenant Name",
      flex: 0.8,
      renderCell: (params) => {
        return <div className="valueStyle">{params?.row?.name}</div>;
      },
    },
    {
      field: "long_description",
      headerName: "Covenant Description",
      flex: 1.2,
      renderCell: (params) => {
        return (
          <div className="valueStyle">{params?.row?.long_description}</div>
        );
      },
    },
    {
      field: "frequency",
      headerName: "Frequency",
      flex: 0.8,
      renderCell: (params) => {
        return <div className="valueStyle">{params?.row?.frequency}</div>;
      },
    },
    {
      field: "shift",
      headerName: "Shift",
      flex: 0.5,
      renderCell: (params) => {
        return <div className="valueStyle">{params?.row?.monthly_shift}</div>;
      },
    },
    {
      field: "other_notes",
      headerName: "Other Note",
      flex: 1.2,
      renderCell: (params) => {
        return <div className="valueStyle">{params?.row?.other_notes}</div>;
      },
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   flex: 0.8,
    //   renderCell: (params) => {
    //     return ;
    //   },
    // },
    {
      field: "action",
      headerName: "Action",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];

  const getMenuList = () => {
    if (roleData && roleData?.["add/edit"] && roleData?.delete) {
      return menu;
    } else if (roleData && roleData?.delete) {
      return [menu[1]];
    } else if (roleData && roleData?.["add/edit"]) {
      return [menu[0]];
    } else return [];
  };

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Covenant created yet!"
          tag="Add Covenant"
          type="Pricing"
          classes="d-flex align-items-center"
          {...(roleData && roleData?.["add/edit"]
            ? {
                handleClick: () => {
                  navigate("add");
                },
                subtitle:
                  "Currently no covenant created yet click on a Create Covenant button to create covenant",
              }
            : {})}
        />
      </>
    );
  });

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const filterData = {
    Frequency: convenantFrequency,
  };

  return (
    <>
      <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingStyle">Covenant</Heading>
        </div>
        <div className="searchButton">
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />
          <FilterComponent
            filterData={filterData}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
          />

          {roleData && roleData?.["add/edit"] && (
            <CustomButton
              text="Add Covenant"
              type="btn-primary"
              handleClick={() => {
                navigate("add");
              }}
            />
          )}
        </div>
      </div>
      <CustomDatagrid
        rows={covenantData?.[0]?.data || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {covenantData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={covenantData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}

      {modalStateDelete.isModalOpen && (
        <DeleteModal
          isActive={modalStateDelete.isModalOpen}
          onClose={handleCloseDelete}
          onClick={handleDelete}
          title="Covenant"
          name={modalStateDelete.name}
        />
      )}
    </>
  );
};

export default Covenant;
