import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../atoms/CustomButton";
import { Heading } from "../../../../atoms/Heading";
import Search from "../../../../atoms/Search";
import style from "./index.module.scss";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import EditBlue from "../../../../icons/EditBlue";
import Pagination from "../../../../atoms/Pagination";
import Delete from "../../../../icons/Delete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import DeleteModal from "../../../../atoms/modals/DeleteModal";
import Edit from "../../../../icons/EditBlue";
import CustomMenu from "../../../../molecules/CustomMenu";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import moment from "moment";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
import { statusData } from "../../../../../utils/staticData";
import View from "../../../../icons/View";
import Modal from "../../../../atoms/modals/Modal";
import ViewInvestor from "./view";
import Upload from "../../../../icons/Upload";
import { downloadReport } from "../../../../../services/downloadService";
import { useSelector } from "react-redux";

const InvestorManagement = () => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Investors;
  const [investorData, setInvestorData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [modalStateView, setModalStateView] = useState({
    modalId: "",
    modalTitle: "",
    modalData: null,
    isModalOpen: false,
  });
  const [filters, setFilters] = useState({});

  const GetInvestorData = async () => {
    try {
      const res = await getData({
        endpoint: "/investor",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          start_date: filters?.startDate0,
          end_date: filters?.endDate0,
        },
      });
      setInvestorData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    GetInvestorData();
  }, [currentPage, itemsPerPage, searchData, filters]);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Investor Added Yet!"
          tag="Add Investor"
          type="Pricing"
          classes="d-flex align-items-center"
          {...(roleData && roleData?.["add/edit"]
            ? {
                handleClick: () => {
                  navigate("/dashboard/investor/add");
                },
                subtitle:
                  "Currently no investor added yet click on a Add Investor button to add investor ",
              }
            : {})}
        />
      </>
    );
  });

  const handleEdit = (data) => {
    navigate(`/dashboard/investor/add/${data?._id}`);
  };

  const menu = [
    {
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        // setModalStateView({
        //   isModalOpen: true,
        //   name: selectedRow.name,
        //   modalData: selectedRow,
        //   modalId: selectedRow._id,
        // });
        // setAnchorEl(null);
        navigate(`view/${selectedRow?._id}`,{
          state: {
            data: selectedRow, // You can pass the entire row or other relevant data
          },
        })
      },
    },
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(`edit/${selectedRow?._id}`);
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.name,
          modalId: selectedRow._id,
        });
        setAnchorEl(null);
      },
    },
  ];

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "investor_name",
      headerName: "Name",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.investor_name}</div>
        );
      },
    },
    {
      field: "investor_code",
      headerName: "Code",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.investor_code}</div>
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1.2,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params?.row?.address}</div>;
      },
    },

    {
      field: "master_agreement_date",
      headerName: "Master Agreement Date",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {moment(params?.row?.master_agreement_date).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      field: "nav",
      headerName: "NAV",
      flex: 0.7,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params?.row?.nav}</div>;
      },
    },
    {
      field: "nav_data",
      headerName: "NAV Date",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {moment(params?.row?.nav_date).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Interested Party Emails",
      flex: 1.3,
      renderCell: (params) => {
        return (
          <div
            className={`${style.valueStyle} no_capitalize`}
            title={params?.row?.interest_party_email}
          >
            {`${params?.row?.interest_party_email[0]} ${
              params?.row?.interest_party_email.length > 1
                ? `..+${params?.row?.interest_party_email.length - 1}`
                : ""
            }`}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.4,
      renderCell: (params, index) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];
  const getMenuList = () => {
    if (
      roleData &&
      roleData?.["add/edit"] &&
      roleData?.view &&
      roleData?.delete
    ) {
      return menu;
    } else if (roleData && roleData?.["add/edit"] && roleData?.view) {
      return [menu[0], menu[1]];
    } else if (roleData && roleData?.["add/edit"] && roleData?.delete) {
      return [menu[1], menu[2]];
    } else if (roleData && roleData?.view && roleData?.delete) {
      return [menu[0], menu[2]];
    } else if (roleData && roleData?.view) {
      return [menu[0]];
    } else if (roleData && roleData?.delete) {
      return [menu[2]];
    } else if (roleData && roleData?.["add/edit"]) {
      return [menu[1]];
    } else return [];
  };
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "investor/delete",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Investor Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetInvestorData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "investor/exportInvestors",
      token: cookies.t,
      fileName: "Investors",
      params: {
        search: searchData,
        start_date: filters?.startDate0,
        end_date: filters?.endDate0,
      },
    });

    if (res) {
      toast.success("Investors Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Investors</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />
          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}

          <FilterComponent
            filterData={[]}
            isDateRange
            dateRangeNames={["Master Agreement Date"]}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
          />

          {roleData && roleData?.["add/edit"] && (
            <CustomButton
              text="Add Investor"
              type="btn-primary"
              handleClick={() => {
                navigate("/dashboard/investor/add");
              }}
            />
          )}
        </div>
      </div>
      <CustomDatagrid
        rows={investorData?.[0]?.roleData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        onCellClick={(data) => {
          console.log('datarow -->  ',data)

            if (data?.field !== "actions") {
              navigate(`view/${data?.row?._id}`,{
                state: {
                  data: data?.row, // You can pass the entire row or other relevant data
                },
              })
            }

        }}
      />
      {investorData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={investorData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <DeleteModal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Investor"
        name={modalStateDelete.name}
      />
      <Modal
        isActive={modalStateView.isModalOpen}
        onClose={() =>
          setModalStateView((prev) => ({
            ...prev,
            isModalOpen: false,
          }))
        }
        title="View Investor Details"
        name={modalStateDelete.name}
      >
        <ViewInvestor data={modalStateView.modalData}></ViewInvestor>
      </Modal>
    </>
  );
};
export default InvestorManagement;
