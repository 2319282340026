import React, { useCallback, useState, memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import Pagination from "../../../../atoms/Pagination";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import NoContentCard from "../../../../atoms/NoContentCard";

import { deleteData, getData, patchData } from "../../../../../services";
import { useCookies } from "react-cookie";
import CustomMenu from "../../../../molecules/CustomMenu";
import Delete from "../../../../icons/Delete";
import Modal from "../../../../atoms/modals/DeleteModal";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import View from "../../../../icons/View";
import Approve from "../../../../icons/Apporve";
import ArchiveIcon from "../../../../icons/Archive";
import Signature from "../../../../icons/Signature";

const content = {
  Draft: {
    title: "No Funding added for Review yet!",
    subtitle: "Currently, no funding has been added for review.",
  },
  "Advance Request": {
    title: "No Funding added in Advance Request yet!",
    subtitle: "Currently, no funding has been added to Advance Request.",
  },
  Submitted: {
    title: "No Funding added in Submitted yet!",
    subtitle: "Currently, no funding are Submitted.",
  },
  Approved: {
    title: "No Funding Approved yet!",
    subtitle: "Currently, no funding have been Approved for processing.",
  },
  Allocated: {
    title: "No Funding Allocated yet!",
    subtitle: "Currently, no funding have been Allocated.",
  },
  Funded: {
    title: "No Funding Funded yet!",
    subtitle: "Currently, no funding have been Funded.",
  },
  Closed: {
    title: "No Funding Closed yet!",
    subtitle: "Currently, no funding have been Closed.",
  },
};

const Table = ({
  type,
  searchData,
  setModalStateAdvReq,
  setModalStateSubmitted,
  setModalStateApproved,
  setModalStateAllocated,
  filters,
  getAllFundingStatus,
  roleData,
  modalStateAdvReq,
  modalStateSubmitted,
  modalStateAllocated,
  modalStateApproved,
}) => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateArchive, setModalStateArchive] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [fundingData, setFundingData] = useState([]);

  const GetFundingData = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getAllFundings",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          status: [type],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setFundingData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    GetFundingData();
  }, [
    itemsPerPage,
    currentPage,
    searchData,
    type,
    modalStateAdvReq,
    modalStateSubmitted,
    modalStateApproved,
    modalStateAllocated,
  ]);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menu = [
    (() => {
      let menuItem = [];
      switch (type) {
        case "Draft":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/draft/view/${selectedRow._id}`);
            },
          });
          break;
        case "Advance Request":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`);
              handleClose();
            },
          });
          menuItem.push({
            icon: <Signature />,
            label: "Status",
            onClick: () => {
              setModalStateAdvReq(() => ({
                isModalOpen: true,
                modalTitle: "Approval:",
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        case "Submitted":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`);
            },
          });
          menuItem.push({
            icon: <Signature />,
            label: "Status",
            onClick: () => {
              setModalStateSubmitted(() => ({
                isModalOpen: true,
                modalTitle: "Approval:",
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        case "Approved":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`);
            },
          });
          menuItem.push({
            icon: <Signature />,
            label: "Status",
            onClick: () => {
              setModalStateApproved(() => ({
                isModalOpen: true,
                modalTitle: "Approval:",
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        case "Allocated":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`);
            },
          });
          menuItem.push({
            icon: <Signature />,
            label: "Status",
            onClick: () => {
              setModalStateAllocated(() => ({
                isModalOpen: true,
                modalTitle: "Approval: Allocated",
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        case "Funded":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`);
            },
          });
          break;
        case "Closed":
          menuItem.push({
            icon: <View />,
            label: "View",
            onClick: () => {
              navigate(`/dashboard/funding/view/${selectedRow?._id}`);
            },
          });
          break;
        case "Archive":
          menuItem.push({
            icon: <Delete />,
            label: "Delete",
            onClick: () => {
              setModalStateDelete(() => ({
                isModalOpen: true,
                modalTitle: selectedRow.client_name,
                modalId: selectedRow?._id,
              }));
              handleClose();
            },
          });
          break;
        default:
          break;
      }
      type !== "Archive" &&
        menuItem.push({
          icon: <ArchiveIcon />,
          label: "Archive",
          onClick: () => {
            setModalStateArchive({
              isModalOpen: true,
              name: selectedRow.client_name,
              modalId: selectedRow._id,
            });
            setAnchorEl(null);
          },
        });
      return menuItem;
    })(),
  ];

  const handleCloseArchive = () => {
    setModalStateArchive(() => ({
      isModalOpen: false,
    }));
  };
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };

  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "funding/deleteFunding",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Funding Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetFundingData();
        getAllFundingStatus();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleArchive = async () => {
    try {
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: modalStateArchive?.modalId,
        },
        data: {
          status: "Archive",
        },
      });
      if (res) {
        toast.success("Funding Archive Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseArchive();
        GetFundingData();
        getAllFundingStatus();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const columns = [
    {
      field: "client",
      headerName: "Client",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.client_name}</div>;
      },
    },
    {
      field: "obligor",
      headerName: "Obligor",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.obligor_name}</div>;
      },
    },
    {
      field: "supplier",
      headerName: "Supplier",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.supplier_name}</div>;
      },
    },
    {
      field: "cost_value",
      headerName: "Cost Value",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{parseFloat(params?.row?.cost_value)}</div>;
      },
    },
    {
      field: "face_value",
      headerName: "Face Value",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div>
            {parseFloat(params?.row?.face_value) +
              parseFloat(params?.row?.cost_value)}
          </div>
        );
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.currency}</div>;
      },
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.transaction_type}</div>;
      },
    },

    {
      field: "actions",
      headerName: "Action",
      flex: 0.4,
      renderCell: (params, index) => {
        return (
          <>
            <div>
              <span
                type="button"
                onClick={(event) => handleStatusMenu(event, params.row)}
              >
                <HorizontalMenu />
              </span>
            </div>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              // items={menu?.[0]}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];
  const getMenuList = () => {
    const getMenuByRoleDraft = () => {
      if (roleData?.["add/edit"] && roleData?.view) {
        return menu[0];
      } else if (roleData?.view) {
        return [menu[0][0]];
      } else if (roleData?.["add/edit"]) {
        return [menu[0][1]];
      } else {
        return [];
      }
    };
    const getMenuByRoleAdvanceR = () => {
      if (roleData?.["add/edit"] && roleData?.view) {
        return menu[0];
      } else if (roleData?.view) {
        return [menu[0][0]];
      } else if (roleData?.["add/edit"]) {
        return [menu[0][1], menu[0][2]];
      } else {
        return [];
      }
    };

    switch (type) {
      case "Draft":
        return getMenuByRoleDraft();
      case "Advance Request":
        return getMenuByRoleAdvanceR();
      case "Submitted":
        return getMenuByRoleAdvanceR();
      case "Approved":
        return getMenuByRoleAdvanceR();
      case "Allocated":
        return getMenuByRoleAdvanceR();
      case "Funded":
        return getMenuByRoleDraft();
      case "Closed":
        return getMenuByRoleDraft();
      case "Archive":
        return roleData && roleData.delete && menu[0];
      default:
        return [];
    }
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title={content[type]?.title}
          subtitle={content[type]?.subtitle}
          type="Pricing"
          classes="d-flex align-items-center"
        />
      </>
    );
  });

  return (
    <>
      <CustomDatagrid
        rows={fundingData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        // checkRequired
        setSelectedRowIds={setSelectedRowIds}
        onCellClick={(data) => {
          if (type === "Draft") {
            if (data?.field !== "actions" && data?.field !== "__check__") {
              navigate(`/dashboard/funding/draft/view/${data?.row._id}`);
            }
          }
        }}
      />
      {fundingData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={fundingData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        isActive={modalStateArchive.isModalOpen}
        onClose={handleCloseArchive}
        onClick={handleArchive}
        title={modalStateArchive?.name}
        name={modalStateArchive.name}
        type="Archive"
      />
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title={modalStateDelete?.name}
        name={modalStateDelete.name}
        type="Delete"
      />
    </>
  );
};

export default Table;
