import React, { useEffect, useMemo, useState } from "react";
import Tabstrip from "../../../../atoms/tabstrip";
import { Box } from "@mui/material";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import { Heading } from "../../../../atoms/Heading";
import { useLocation, useParams } from "react-router-dom";
import { isDraft } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import Valuation from "./valuation";
import DashboardMonitor from "./dashboard";
import Analysis from "./analysis";

const DetailsMonitoring = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Dashboard");
  const accessData = useSelector((state) => state?.accessData?.accessData);

  const { id, isDraft } = useParams();

  useEffect(() => {
    setActiveTab("Dashboard");
  }, []);

  const data = useMemo(
    () => [
      // ...(accessData && accessData?.["Role Group Management"]
      //   ? [
      {
        id: 1,
        label: "Dashboard",
        view: <DashboardMonitor />,
      },
      //   ]
      // : []),
      // ...(accessData && accessData?.["Role Management"]
      //   ? [
      {
        id: 2,
        label: "Analysis",
        view: <Analysis />,
      },
      //   ]
      // : []),
      // ...(accessData && accessData?.["User Management"]
      //   ? [
      {
        id: 3,
        label: "Valuation",
        view: <Valuation />,
      },
      //   ]
      // : []),
    ],
    // [],
  );

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };
  const handleAllowId = () => {
    if (location?.state?.edit === "Client") {
      return 1;
    } else if (location?.state?.edit === "Supplier") {
      return 2;
    } else if (localStorage?.state?.edit === "Obligor") {
      return 3;
    } else {
      return 4;
    }
  };

  return (
    <>
      {/* <Breadcrumb
        labels={[
          "Counterparties",
          `${
            id
              ? isDraft
                ? "Edit Draft Counterparty"
                : "Edit Counterparty"
              : "Add Counterparty"
          }`,
        ]}
        stepsToRemove={id && isDraft ? 2 : 1}
      /> */}
      <Heading className="addHeaderStyle">{activeTab} </Heading>

      <Tabstrip
        activeTab={activeTab}
        onTabChange={onTabChange}
        data={data}
        allowedTabId={location?.state?.edit ? handleAllowId : ""}
        areTabsDisabled={location?.state?.edit ? true : false}
      />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>{_getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};

export default DetailsMonitoring;
