import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Heading } from "../../../../atoms/Heading";
import TextInput from "../../../../atoms/TextInput";
import MyInput from "../../../../atoms/MyInput";
import MyDropdown from "../../../../atoms/MyDropdown";
import {
  agreementCounterPartyData,
  BrokerTypeData,
  CarrierNameData,
  CurrencyData,
  GICSectorData,
  GICSIndustryData,
  LimitTypeData,
  PolicyNameData,
  ReportingData,
  transactionType,
  VerifiedData,
} from "../../../../../utils/staticData";
import * as Yup from "yup";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import MyTextArea from "../../../../atoms/MyTextArea";
import style from "../index.module.scss";
import {
  useBlocker,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import CustomButton from "../../../../atoms/CustomButton";
import { getData, patchData, postData } from "../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import { useCookies } from "react-cookie";
import { countryNamesData } from "../../../../../utils/countryStaticData";
import Modal from "../../../../atoms/modals/Modal";
import InputLayout from "../../../../atoms/InputLayout";
import SelectDropdown from "../../../../atoms/Dropdown";
import WarningModal from "../../../../atoms/modals/warningModal";
import { emailRegex, numberRegex } from "../../../../../helpers/expirationTime";
import TagDropdown from "../../../../atoms/MyDropdown/TagDropdown";
import Error from "../../../../atoms/Error";
import { width } from "@mui/system";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import { LabelDisplay } from "../view";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccessData,
  stateSelector,
} from "../../../../../store/slices/accessSlice";

const Basic = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies] = useCookies(["t", "role"]);
  const { id, isDraft } = useParams();
  const [counterData, setCounterData] = useState([]);
  const [brokerData, setBrokerData] = useState([]);
  const [signerData, setSignerData] = useState([]);
  const [pricingData, setPricingData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [insuranceData, setInsuranceData] = useState([]);
  const [copyDirty, setCopyDirty] = useState();
  const [copyValues, setCopyValues] = useState();
  // const [copyBrokerValues, setCopyBrokerValues] = useState();
  const [copyType, setCopyType] = useState(false);
  const [modalState, setModalState] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false,
  });
  const [modalStateBroker, setModalStateBroker] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [modalStateInsurance, setModalStateInsurance] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [emailError, setEmailError] = useState({
    isActive: null,
    data: null,
  });

  const [counterError, setCounterError] = useState({
    isActive: null,
    data: null,
  });

  const [brokerExistError, setrBrokerExistError] = useState({
    isActive: null,
    data: null,
  });

  const [inusuranceItem, setInsuranceItem] = useState({
    isActive: null,
    data: null,
  });

  const [counterSubmitting, setCounterSubmitting] = useState(false);
  const [brokerError, setBrokerError] = useState("");
  const [brokerModalError, setBrokerModalError] = useState("");

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      // (id ? isDraft === "123" && !copyType : true) &&
      !copyType &&
      copyDirty &&
      currentLocation.pathname !== nextLocation.pathname,
  );

  const handleClose = () => {
    setModalState(() => ({
      isModalOpen: false,
    }));
  };
  const handleCloseBroker = (setValues) => {
    console.log("Values :", setValues);

    setModalStateBroker(() => ({
      isModalOpen: false,
      setBrokerValues: setValues,
    }));
  };
  const handleCloseInsurance = () => {
    setModalStateInsurance(() => ({
      isModalOpen: false,
    }));
  };

  const getSignerList = async () => {
    try {
      const res = await getData({
        endpoint: "signer/getSignersList",
        token: cookies.t,
      });
      res?.data?.unshift({ value: "Add new signer", label: "Add new signer" });
      const modifiedRes = res?.data?.map(({ value, label }) => ({
        value,
        label,
      }));
      setSignerData(modifiedRes);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getBrokerList = async () => {
    try {
      const res = await getData({
        endpoint: "broker/getBrokersList",
        token: cookies.t,
      });
      res?.data?.unshift({ value: "Add new broker", label: "Add new broker" });
      // const modifiedRes = res?.data?.map(({ value, label,tag }) => ({
      //   value,
      //   label,
      // }));
      setBrokerData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getPricingData = async () => {
    try {
      const res = await getData({
        endpoint: "price/GetPriceList",
        token: cookies.t,
      });
      setPricingData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const pricingDataLabel = pricingData?.map((item) => {
    return {
      value: item?.id,
      label:
        item?.rate_type === "Flat"
          ? `Flat Rate: ${item?.top_rate}% ADV: ${item?.advance_rate}`
          : `${item?.base_rate_name} + ${item?.top_rate}% ADV: ${item?.advance_rate} `,
    };
  });
  const getCounterPartyData = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getDataWithCounterType",
        token: cookies.t,
        params: {
          conterparty_type: "Client",
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getInsuranceData = async () => {
    try {
      const res = await getData({
        endpoint: "insurance/getAllInsuranceList",
        token: cookies.t,
      });
      res?.data?.unshift({
        value: "Add new Insurance",
        label: "Add new Insurance",
      });
      setInsuranceData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getSignerList();
    getBrokerList();
    getPricingData();
    getCounterPartyData();
    getInsuranceData();
  }, []);

  const handleSubmitSigner = async (value) => {
    try {
      const res = await postData({
        endpoint: "signer/createSigner",
        token: cookies.t,
        data: { ...value },
      });
      if (res) {
        toast.success("Signer Created Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        await handleClose();
        await getSignerList();
        await modalState.setValues({
          ...copyValues,
          signerDetails: [
            ...copyValues.signerDetails,
            {
              label: `${res?.data?.data?.first_name} ${res?.data?.data?.last_name}`,
              value: res?.data?.data?._id,
            },
          ],
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleSubmitBroker = async (value) => {
    try {
      const res = await postData({
        endpoint: "broker/create",
        token: cookies.t,
        data: value,
      });
      if (res) {
        toast.success("Broker Created Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        await handleCloseBroker();
        await getBrokerList();
        await modalStateBroker.setValues({
          ...copyValues,
          brokerDetails: [
            ...copyValues.brokerDetails,
            {
              label: `${res?.data?.data?.broker_name}`,
              value: res?.data?.data?._id,
            },
          ],
          brokerStatus: copyValues.brokerStatus.includes(value.broker_type[0])
            ? copyValues.brokerStatus
            : copyValues.brokerStatus + value.broker_type[0],
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleSubmitInsurance = async (value) => {
    try {
      const res = await postData({
        endpoint: "insurance/createInsurance",
        token: cookies.t,
        data: value,
      });
      if (res) {
        toast.success("Insurance Details Added Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseInsurance();
        getInsuranceData();
        await modalStateInsurance.setValues({
          ...copyValues,
          insuranceDetails: res?.data?.data?._id,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const initialValues = {
    counter_type: counterData.counter_type ? counterData.counter_type : type,
    client_name: counterData.client_name || "",
    client_id: counterData?.client_id || null,
    counter_name: counterData.counter_name || "",
    address: counterData.address || "",
    tax_id: counterData.tax_id || "",
    credit_limit: counterData.credit_limit || null,
    primary_currency: counterData.primary_currency || "",
    gics_sector: counterData.gics_sector || "",
    gics_industry: counterData.gics_industry || "",
    geography: counterData.geography || "",
    db_number: counterData.db_number || "",
    sp_number: counterData.sp_number || null,
    pricing_id: counterData.pricing_id || "",
    agreement_date: counterData.agreement_date || "",
    agreement_counter: counterData.agreement_counter || "",
    trans_type: counterData.trans_type || "",
    resolver: counterData.resolver || "",
    notes: counterData.notes || "",
    verified: counterData.verified || "",
    signerDetails: counterData?.signerDetails || [],
    brokerDetails: counterData?.brokerDetails || [],
    insuranceDetails: counterData?.insuranceDetails
      ? counterData?.insuranceDetails?.[0]?.value
      : "",
    status: "",
    brokerStatus: counterData?.brokerStatus || "",
  };

  const validationSchema = Yup.object().shape({
    client_id: Yup.string().when("counter_type", (value) => {
      if (value[0] === "Supplier" || value[0] === "Obligor") {
        return Yup.string().required("Client ID is required");
      }
      return Yup.string().notRequired();
    }),
    counter_name: Yup.string().required("Counterparty Name is required"),
    address: Yup.string().required("Address is required"),
    tax_id: Yup.string()
      .matches(numberRegex, "Invalid Tax id")
      .required("Tax ID is required"),
    credit_limit: Yup.string().required("Credit Limit is required"),
    primary_currency: Yup.string().required("Primary Currency is required"),
    gics_sector: Yup.string().required("GICS Sector is required"),
    gics_industry: Yup.string().required("GICS Industry is required"),
    geography: Yup.string().required("Geography is required"),
    db_number: Yup.string()
      .matches(
        /^\d{2}-\d{3}-\d{4}$|^\d{9}$/,
        "D&B Number must be in the format XX-XXX-XXXX or XXXXXXXXX  and contain only numeric characters",
      )
      .required("D&B Number is required"),
    sp_number: Yup.number().required("S&P Number is required"),
    pricing_id: Yup.string().when("counter_type", (value) => {
      if (value[0] === "Client" || value[0] === "Obligor") {
        return Yup.string().required("Pricing ID is required");
      }
      return Yup.string().notRequired();
    }),
    agreement_date: Yup.date().when("counter_type", (value) => {
      if (value[0] === "Client") {
        return Yup.date().required("Agreement Date is required");
      }
      return Yup.date().notRequired();
    }),
    agreement_counter: Yup.string().when("counter_type", (value) => {
      if (value[0] === "Client") {
        return Yup.string().required("Agreement Counter is required");
      }
      return Yup.string().notRequired();
    }),
    trans_type: Yup.string().when("counter_type", (value) => {
      if (value[0] === "Client") {
        return Yup.string().required("Transaction Type is required");
      }
      return Yup.string().notRequired();
    }),
    verified: Yup.string().when("counter_type", (value) => {
      if (value[0] === "Supplier" || value[0] === "Obligor") {
        return Yup.string().required("Verified is required");
      }
      return Yup.string().notRequired();
    }),
    signerDetails: Yup.array().min(1, "At least one Signer is required"),
    brokerDetails: Yup.array().min(1, "At least one Broker is required"),
    // insurance_ids: Yup.string().when("counter_type", (value) => {
    //   if (value[0] === "Obligor") {
    //     return Yup.string().required("Insurance is required");
    //   }
    //   return Yup.string().notRequired();
    // }),
  });
  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      if (res?.data) {
        const transformedData = {
          ...res.data,

          signerDetails: res.data.signerDetails
            ? res.data.signerDetails.map((signer) => ({
                value: signer._id,
                label: signer.client_name,
              }))
            : [],
          brokerDetails: res.data.brokerDetails
            ? res.data.brokerDetails.map((broker) => ({
                value: broker._id,
                label: broker.broker_name,
              }))
            : [],
          insuranceDetails: res.data.insuranceDetails
            ? res.data.insuranceDetails.map((item) => ({
                value: item._id,
                label: item.policy_name,
              }))
            : [],
        };

        setCounterData(transformedData);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id) {
      getCounterDataById();
    }
  }, [id]);

  const checkEmail = async (newVal) => {
    const response = await getData({
      endpoint: "signer/checkSignerExist",
      params: {
        email: newVal,
      },
      token: cookies.t,
    });
    if (response.data && response.data.status) {
      setEmailError(() => ({
        isActive: "Email Already Exists",
        data: response?.data?.data,
      }));
    } else {
      setEmailError(() => ({
        isActive: "",
        data: "",
      }));
    }
  };

  const checkCounterExists = async (newVal) => {
    const response = await getData({
      endpoint: "couterParty/checkCounter",
      params: {
        counter_name: newVal.counter_name,
        counter_type:
          newVal.counter_type !== "Client" ? newVal.counter_type : null,
        trans_type: newVal.trans_type ? newVal.trans_type : null,
      },
      token: cookies.t,
    });
    if (response.data) {
      setCounterError(() => ({
        isActive: "Countername Already Exists",
        data: response?.data?.data,
      }));
    } else {
      setCounterError(() => ({
        isActive: "",
        data: "",
      }));
    }
  };

  const checkbrokerExist = async (values) => {
    const response = await getData({
      endpoint: "broker/checkBroker",
      params: {
        broker_name: values.broker_name,
        broker_type: values.broker_type,
        fee_rate: values.fee_rate,
      },
      token: cookies.t,
    });
    if (response.data && response.data.status) {
      setrBrokerExistError(() => ({
        isActive: `Broker Already Exists Please Select ${response?.data?.data?.broker_name} from the Existing brokers in the list`,
        data: response?.data?.data,
      }));
    } else {
      setrBrokerExistError(() => ({
        isActive: "",
        data: "",
      }));
    }
  };

  useEffect(() => {
    setrBrokerExistError(() => ({
      isActive: "",
      data: "",
    }));
  }, [modalStateBroker.isModalOpen]);

  const handleSubmit = async (values, statustype) => {
    const dataTOSend = {
      address: values?.address,
      agreement_counter: values?.agreement_counter,
      agreement_date: values?.agreement_date,
      client_id: values?.client_id,
      client_name: values?.client_name,
      counter_name: values?.counter_name
        ? capitalizeLetter(values.counter_name)
        : values.carrier_name,
      counter_type: values?.counter_type,
      credit_limit: values?.credit_limit,
      db_number: values?.db_number,
      geography: values?.geography,
      gics_industry: values?.gics_industry,
      gics_sector: values?.gics_sector,
      insuranceDetails: values?.insuranceDetails
        ? [values.insuranceDetails]
        : [],
      notes: values?.notes,
      pricing_id: values?.pricing_id,
      primary_currency: values?.primary_currency,
      resolver: values?.resolver,
      sp_number: values?.sp_number,
      tax_id: values?.tax_id,
      trans_type: values?.trans_type,
      verified: values?.verified,
      status: statustype && statustype.trim() === "Draft" ? "Draft" : "Active",
      signerDetails: values.signerDetails
        ? values.signerDetails.map((item) => item.value)
        : [],
      brokerDetails: values.brokerDetails
        ? values.brokerDetails.map((item) => item.value)
        : [],
      brokerStatus: values.brokerStatus,
    };
    setCopyType(true);
    setCounterSubmitting(true);
    if (id) {
      try {
        const res = await patchData({
          endpoint: "couterParty/updateCounter",
          token: cookies.t,
          params: {
            id: id,
          },
          data: {
            ...dataTOSend,
          },
        });
        if (res) {
          toast.success("CounterParty Details Updated Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          if (blocker.state === "blocked") blocker.proceed();
          navigate("/dashboard/counterparty");
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setCounterSubmitting(false);
      }
    } else {
      try {
        const res = await postData({
          endpoint: "couterParty/createCounter",
          token: cookies.t,
          data: {
            ...dataTOSend,
          },
        });
        if (res) {
          toast.success("CounterParty Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          if (blocker.state === "blocked") blocker.proceed();
          navigate("/dashboard/counterparty");
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setCounterSubmitting(false);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnChange
        validateOnBlur
      >
        {({
          values,
          touched,
          errors,
          dirty,
          setFieldValue,
          setValues,
          setFieldTouched,
          handleBlur,
          isValid,
          isSubmitting,
        }) => {
          setCopyDirty(dirty);
          setCopyValues(values);
          return (
            <>
              {(type === "Supplier" || type === "Obligor") && (
                <MyDropdown
                  placeholder="Client Name"
                  data={clientData}
                  onChange={(option) => {
                    setFieldValue("client_id", option?.value);
                    setFieldValue("client_name", option?.label);
                  }}
                  handleBlur={(e) => {
                    setFieldTouched("client_id", true);
                    handleBlur(e);
                  }}
                  selectedValue={counterData?.client_id}
                  format="all"
                  menuOpen={true}
                  name="client_id"
                  touched={touched.client_id}
                  errors={errors.client_id}
                  width="32.426%"
                />
              )}
              <Heading className="h5 mb-3 mt-3">
                Counterparty Information
              </Heading>
              <div
                className="d-flex flex-wrap w-100"
                style={{ columnGap: "1.36%", rowGap: "16px" }}
              >
                {/* <MyInput
                  type="text"
                  name="counter_name"
                  customholder="Counterparty Name"
                  width="32.426%"
                /> */}
                <div style={{ width: "32.426%" }}>
                  <TextInput
                    type="text"
                    name="counter_name"
                    customholder="Counterparty Name"
                    customBlur={(e) => {
                      checkCounterExists(values);
                    }}
                    InputHeight="small"
                    blurError={counterError.isActive}
                  />
                </div>

                <MyInput
                  type="text"
                  name="address"
                  customholder="Address"
                  width="66.214%"
                />
              </div>
              <div
                className="d-flex flex-wrap w-100 mt-3"
                style={{ columnGap: "1.36%", rowGap: "16px" }}
              >
                <MyInput
                  type="text"
                  name="tax_id"
                  customholder="Tax ID"
                  width="32.426%"
                />
                <MyInput
                  type="number"
                  name="credit_limit"
                  customholder="Credit Limit"
                  width="32.426%"
                />
                <MyDropdown
                  placeholder="Primary Currency"
                  data={CurrencyData}
                  onChange={(option) => {
                    setFieldValue("primary_currency", option);
                  }}
                  handleBlur={(e) => {
                    setFieldTouched("primary_currency", true);
                    handleBlur(e);
                  }}
                  selectedValue={values?.primary_currency}
                  name="primary_currency"
                  touched={touched.primary_currency}
                  errors={errors.primary_currency}
                  width="32.426%"
                />
              </div>
              {/* <MyTextArea as="textarea" name="address" customholder="Address" /> */}

              <Heading className="h5 mb-3 mt-3">
                Counterparty Categories
              </Heading>
              <div
                className="d-flex flex-wrap w-100 "
                style={{ columnGap: "1.36%", rowGap: "16px" }}
              >
                <MyDropdown
                  placeholder="GICS Sector"
                  data={GICSectorData}
                  onChange={(option) => {
                    setFieldValue("gics_sector", option);
                  }}
                  handleBlur={(e) => {
                    setFieldTouched("gics_sector", true);
                    handleBlur(e);
                  }}
                  selectedValue={values?.gics_sector}
                  menuOpen={true}
                  name="gics_sector"
                  touched={touched.gics_sector}
                  errors={errors.gics_sector}
                  width="32.426%"
                />
                <MyDropdown
                  placeholder="GICS Industry"
                  data={GICSIndustryData}
                  onChange={(option) => {
                    setFieldValue("gics_industry", option);
                  }}
                  handleBlur={(e) => {
                    setFieldTouched("gics_industry", true);
                    handleBlur(e);
                  }}
                  selectedValue={values?.gics_industry}
                  menuOpen={true}
                  name="gics_industry"
                  touched={touched.gics_industry}
                  errors={errors.gics_industry}
                  width="32.426%"
                />
                <MyDropdown
                  placeholder="Geography"
                  data={countryNamesData}
                  onChange={(option) => {
                    setFieldValue("geography", option);
                  }}
                  handleBlur={(e) => {
                    setFieldTouched("geography", true);
                    handleBlur(e);
                  }}
                  selectedValue={values?.geography}
                  menuOpen={true}
                  name="geography"
                  touched={touched.geography}
                  errors={errors.geography}
                  width="32.426%"
                />
                <MyInput
                  type="text"
                  name="db_number"
                  customholder="D&B Number"
                  width="32.426%"
                />
                <MyInput
                  type="number"
                  name="sp_number"
                  customholder="S&P Number"
                  width="32.426%"
                />
              </div>
              <Heading className="h5 mb-3 mt-3">Other Information</Heading>
              <div
                className="d-flex flex-wrap"
                style={{ columnGap: "1.36%", rowGap: "16px" }}
              >
                {(type === "Client" || type === "Obligor") && (
                  <>
                    <MyDropdown
                      placeholder="Pricing ID"
                      data={pricingDataLabel}
                      onChange={(option) => {
                        console.log("Pricing :", option);

                        setFieldValue("pricing_id", option);
                      }}
                      handleBlur={(e) => {
                        setFieldTouched("pricing_id", true);
                        handleBlur(e);
                      }}
                      selectedValue={values?.pricing_id}
                      menuOpen={true}
                      name="pricing_id"
                      touched={touched.pricing_id}
                      errors={errors.pricing_id}
                      width="32.426%"
                    />
                    {type === "Client" && (
                      <div style={{ position: "relative", width: "32.426%" }}>
                        <DateSelector
                          placeholder="Agreement Date"
                          onChange={async (option) => {
                            setFieldValue("agreement_date", option);
                          }}
                          handleBlur={(e) => {
                            setFieldTouched("agreement_date", true);
                            handleBlur(e);
                          }}
                          selectedDate={values.agreement_date}
                          errors={errors.agreement_date}
                          touched={touched.agreement_date}
                        />
                      </div>
                    )}
                    {console.log("values :", values)}
                    {type === "Client" && (
                      <MyDropdown
                        placeholder="Agreement Counterparty"
                        data={agreementCounterPartyData}
                        onChange={(option) => {
                          setFieldValue("agreement_counter", option);
                        }}
                        handleBlur={(e) => {
                          setFieldTouched("agreement_counter", true);
                          handleBlur(e);
                        }}
                        selectedValue={values?.agreement_counter}
                        menuOpen={true}
                        name="agreement_counter"
                        touched={touched.agreement_counter}
                        errors={errors.agreement_counter}
                        width="32.426%"
                      />
                    )}
                  </>
                )}
                {(type === "Signer" ||
                  type === "Supplier" ||
                  type === "Obligor") && (
                  <MyDropdown
                    placeholder="Verified"
                    data={VerifiedData}
                    onChange={(option) => {
                      setFieldValue("verified", option);
                    }}
                    handleBlur={(e) => {
                      setFieldTouched("verified", true);
                      handleBlur(e);
                    }}
                    selectedValue={values?.verified}
                    menuOpen={true}
                    name="verified"
                    touched={touched.verified}
                    errors={errors.verified}
                    width="32.426%"
                  />
                )}
                {type === "Client" && (
                  <MyDropdown
                    placeholder="Transaction Type"
                    data={transactionType}
                    onChange={(option) => {
                      setFieldValue("trans_type", option);
                    }}
                    handleBlur={(e) => {
                      setFieldTouched("trans_type", true);
                      handleBlur(e);
                      checkCounterExists(values);
                    }}
                    selectedValue={values?.trans_type}
                    menuOpen={true}
                    name="trans_type"
                    touched={touched.trans_type}
                    errors={errors.trans_type}
                    width="32.426%"
                  />
                )}
                <MyDropdown
                  placeholder="Signer"
                  data={signerData}
                  onChange={(option) => {
                    if (
                      option.find((item) => item.value === "Add new signer")
                    ) {
                      setModalState(() => ({
                        isModalOpen: true,
                        modalTitle: "Add New Signer",
                        setValues: setValues,
                      }));
                    } else {
                      if (option?.[0]?.value !== "Add new signer") {
                        setFieldValue("signerDetails", option);
                      }
                    }
                  }}
                  format="all"
                  handleBlur={(e) => {
                    setFieldTouched("signerDetails", true);
                    handleBlur(e);
                  }}
                  selectedValue={
                    values?.signerDetails && values?.signerDetails.length > 0
                      ? values?.signerDetails?.map((item) => item?.value)
                      : null
                  }
                  menuOpen={true}
                  name="signerDetails"
                  touched={touched.signerDetails}
                  errors={errors.signerDetails}
                  width="32.426%"
                  multiSelect={true}
                />
                <div style={{ width: "32.426%" }}>
                  <TagDropdown
                    placeholder="Broker"
                    data={brokerData}
                    onChange={async (option) => {
                      if (
                        option.find((item) => item.value === "Add new broker")
                      ) {
                        if (values.brokerStatus.length === 2) {
                          setBrokerError(
                            "You Can Only Add One Primary Broker and One Secondary Broker",
                          );
                        }
                        if (values.brokerStatus.length !== 2) {
                          if (modalStateBroker?.setBrokerValue) {
                            await modalStateBroker?.setBrokerValues({
                              broker_name: "",
                              fee_rate: "",
                              broker_type: "",
                            });
                          }
                          setModalStateBroker(() => ({
                            isModalOpen: true,
                            modalTitle: "Add New Broker",
                            setValues: setValues,
                          }));
                        }
                      } else if (
                        option.length === 3 &&
                        values?.brokerDetails?.[0]?.tag?.[0] ===
                          option[option.length - 1]?.tag?.[0]
                      ) {
                        setBrokerError(
                          "You Can Only Add One Primary Broker and One Secondary Broker",
                        );
                      } else if (option.length === 0) {
                        setFieldValue("brokerStatus", "");
                        setFieldValue("brokerDetails", []);
                      } else {
                        if (
                          ((values?.brokerStatus?.length !== 1 &&
                            (option[option.length - 1]?.tag[0] !== "P" ||
                              option[option.length - 1]?.tag[0] !== "S")) ||
                            (values?.brokerStatus?.length !== 2 &&
                              (option[option.length - 1]?.tag[0] !== "P" ||
                                option[option.length - 1]?.tag[0] !== "S" ||
                                option[option.length - 1]?.tag[0] !== "P" ||
                                option[option.length - 1]?.tag[0] !== "S"))) &&
                          values.brokerStatus.length !== 2
                        ) {
                          if (
                            values.brokerStatus.includes(
                              option[option.length - 1]?.tag[0],
                            )
                          ) {
                            setBrokerError(
                              "You Can Only Add One Primary Broker and One Secondary Broker",
                            );
                          } else if (
                            values?.brokerStatus?.[0] !==
                              option[option.length - 1]?.tag[0] &&
                            values?.brokerStatus?.[1] !==
                              option[option.length - 1]?.tag[0]
                          ) {
                            setFieldValue("brokerDetails", option);
                            setFieldValue(
                              "brokerStatus",
                              values.brokerStatus +
                                option[option.length - 1].tag[0],
                            );
                            setBrokerError("");
                          }
                        } else {
                          console.log("entered option to remove:", option);

                          if (
                            values.brokerStatus.length <= 2 &&
                            option.length < 2
                          ) {
                            if (values.brokerStatus.length === 1)
                              setFieldValue("brokerStatus", "");

                            if (option?.[0]?.tag?.[0] === "P") {
                              setFieldValue(
                                "brokerStatus",
                                values.brokerStatus.replace("S", ""),
                              );
                            } else {
                              setFieldValue(
                                "brokerStatus",
                                values.brokerStatus.replace("P", ""),
                              );
                            }
                            setFieldValue("brokerDetails", option);
                          }
                        }
                      }
                    }}
                    format="all"
                    handleBlur={(e) => {
                      setFieldTouched("brokerDetails", true);
                      handleBlur(e);
                    }}
                    selectedValue={
                      values?.brokerDetails && values?.brokerDetails.length > 0
                        ? values?.brokerDetails?.map((item) => item?.value)
                        : null
                    }
                    menuOpen={true}
                    name="brokerDetails"
                    touched={touched.brokerDetails}
                    errors={errors.brokerDetails}
                    info={brokerError}
                    multiSelect
                    tagSplit={false}
                  />
                </div>
                {console.log("insurance :", insuranceData)}
                {type === "Obligor" && (
                  <div style={{ width: "32.426%" }}>
                    <TagDropdown
                      placeholder="Insurance"
                      data={insuranceData}
                      onChange={(option) => {
                        console.log("option :", option);

                        if (option && option?.value === "Add new Insurance") {
                          setModalStateInsurance(() => ({
                            isModalOpen: true,
                            modalTitle: "Add New Insurance",
                            setValues: setValues,
                          }));
                        } else {
                          if (option && option?.value !== "Add new Insurance") {
                            setFieldValue("insuranceDetails", option?.value);
                          }
                        }
                      }}
                      handleBlur={(e) => {
                        setFieldTouched("insuranceDetails", true);
                        handleBlur(e);
                      }}
                      format="all"
                      selectedValue={
                        values?.insuranceDetails &&
                        values?.insuranceDetails.length > 0
                          ? values?.insuranceDetails
                          : null
                      }
                      // multiSelect
                      menuOpen={true}
                      name="insuranceDetails"
                      touched={touched.insuranceDetails}
                      errors={errors.insuranceDetails}
                      tagSplit={false}
                      setInsuranceItem={setInsuranceItem}
                      isInfoData={true}
                    />
                  </div>
                )}
                <MyTextArea as="textarea" customholder="Notes" name="notes" />
              </div>

              <div className={style.buttonWrap}>
                <div>
                  <CustomButton
                    text="Back"
                    size="btn-lg"
                    type="btn-outline-dark"
                    handleClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
                <div className={style.resetAdd}>
                  {(id ? isDraft === "123" : true) && (
                    <CustomButton
                      text="Save as Draft"
                      type="btn-outline-primary"
                      size="btn-lg"
                      disabled={!dirty}
                      handleClick={() => handleSubmit(values, "Draft")}
                    />
                  )}
                  <CustomButton
                    text="Reset"
                    type="btn-outline-primary"
                    size="btn-lg"
                  />
                  <button
                    className="btn-primary btn-lg"
                    type="submit"
                    disabled={
                      !isValid ||
                      !dirty ||
                      isSubmitting ||
                      counterSubmitting ||
                      counterError.isActive
                    }
                    onClick={() => handleSubmit(values)}
                  >
                    {id ? "Update" : "Add"}
                    {isSubmitting && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
      {/* this is signer create model */}
      <Modal
        isActive={modalState.isModalOpen}
        title={modalState.modalTitle}
        onClose={handleClose}
      >
        <>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
            }}
            validationSchema={Yup.object().shape({
              first_name: Yup.string().required("First name is required"),
              last_name: Yup.string().required("Last name is required"),
              email: Yup.string()
                .matches(emailRegex, "Invalid email")
                .required("Email is Required"),
            })}
            enableReinitialize
            validateOnChange
            validateOnBlur
          >
            {({
              values,
              touched,
              errors,
              setFieldValue,
              setFieldTouched,
              handleBlur,
              isValid,
              dirty,
              isSubmitting,
            }) => (
              <>
                <div className="mt-2">
                  <InputLayout>
                    <TextInput
                      type="text"
                      name="first_name"
                      placeholder=""
                      customholder="First Name"
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      InputHeight="small"
                    />
                  </InputLayout>
                </div>
                <div className="mt-4">
                  <InputLayout>
                    <TextInput
                      type="text"
                      name="last_name"
                      placeholder=""
                      customholder="Last Name"
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      InputHeight="small"
                    />
                  </InputLayout>
                </div>
                <div className="mt-4">
                  <InputLayout>
                    <TextInput
                      type="text"
                      name="email"
                      placeholder=""
                      customholder="Email"
                      customBlur={(e) => {
                        checkEmail(values.email);
                      }}
                      InputHeight="small"
                      blurError={emailError.isActive}
                    />
                  </InputLayout>
                </div>
                <div className={style.buttonWrap}>
                  <div className={style.resetAdd}>
                    <CustomButton
                      text="Close"
                      type="btn-outline-primary"
                      size="btn-lg"
                      handleClick={() => handleClose()}
                    />
                    <button
                      className="btn-primary btn-lg"
                      type="submit"
                      disabled={
                        !isValid ||
                        !dirty ||
                        isSubmitting ||
                        !!emailError.isActive
                      }
                      style={{ width: "100%" }}
                      onClick={() => handleSubmitSigner(values)}
                    >
                      {id ? "Update" : "Add"}
                      {isSubmitting && (
                        <span
                          className="spinner-border spinner-border-sm ms-2 text-light"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                </div>
                <WarningModal
                  isActive={emailError?.isActive}
                  title={"Email Exists"}
                  innerdescription={`These Signer whose name <b>${emailError?.data?.first_name} ${emailError?.data?.last_name}</b> with email <b>${emailError?.data?.email}</b>  is Already Exists,Are you proceed with these existing Signer`}
                  onClose={() => {
                    setFieldValue("email", "");
                    setEmailError(() => ({
                      isActive: "",
                      data: "",
                    }));
                  }}
                  onClick={() => {
                    modalState.setValues({
                      ...copyValues,
                      signerDetails: [
                        ...copyValues.signerDetails,
                        {
                          label: `${emailError?.data?.first_name} ${emailError?.data?.last_name}`,
                          value: emailError?.data?.id,
                        },
                      ],
                    });
                    setEmailError(() => ({
                      isActive: "",
                      data: "",
                    }));
                    handleClose();
                  }}
                ></WarningModal>
              </>
            )}
          </Formik>
        </>
      </Modal>

      {/*This is Broker modal  */}
      {modalStateBroker.isModalOpen && (
        <>
          <Formik
            initialValues={{
              broker_name: "",
              fee_rate: "",
              broker_type: "",
            }}
            validationSchema={Yup.object().shape({
              broker_type: Yup.string().required("Broker Type is required"),
              broker_name: Yup.string().required("Broker Name is required"),
              fee_rate: Yup.number()
                .typeError("Fee Rate must be a number") // Ensure it's a number
                .required("Fee Rate is required") // Required field
                .min(0, "Fee Rate must be at least 0") // Ensure it's not negative
                .max(1, "Fee Rate must be less than 1"),
            })}
            enableReinitialize
            validateOnChange
            validateOnBlur
          >
            {({
              values,
              touched,
              errors,
              setValues,
              setFieldValue,
              setFieldTouched,
              handleBlur,
              isValid,
              dirty,
              isSubmitting,
              resetForm,
            }) => {
              // setCopyBrokerValues(values);
              return (
                <>
                  <Modal
                    isActive={modalStateBroker.isModalOpen}
                    title={modalStateBroker.modalTitle}
                    onClose={() => handleCloseBroker(setCopyValues)}
                  >
                    <div className="mt-2">
                      <InputLayout>
                        <TextInput
                          type="text"
                          name="broker_name"
                          placeholder=""
                          customholder="Broker Name"
                          customBlur={(e) => {
                            checkbrokerExist(values);
                          }}
                          InputHeight="small"
                        />
                      </InputLayout>
                    </div>
                    <div className="mt-4">
                      <InputLayout>
                        <TextInput
                          type="text"
                          name="fee_rate"
                          placeholder=""
                          customholder="Fee Rate"
                          customBlur={(e) => {
                            checkbrokerExist(values);
                          }}
                          InputHeight="small"
                        />
                      </InputLayout>
                    </div>
                    <div className="mt-4">
                      <MyDropdown
                        placeholder="Broker Type"
                        data={BrokerTypeData}
                        onChange={(option) => {
                          console.log("Modal :", option);

                          if (copyValues.brokerStatus.includes(option[0])) {
                            setBrokerModalError(
                              `You Have Already Added ${
                                option[0] === "P" ? "Primary" : "Secondary"
                              } Broker,Only One Primary Broker and One Secondary Broker Can be Accepted`,
                            );
                          } else {
                            setFieldValue("broker_type", option);
                            setBrokerModalError("");
                          }
                        }}
                        selectedValue={values?.broker_type}
                        handleBlur={(e) => {
                          setFieldTouched("broker_type", true);
                          handleBlur(e);
                          checkbrokerExist(values);
                        }}
                        touched={touched.broker_type}
                        errors={brokerModalError}
                      />
                    </div>
                    {brokerExistError.isActive && (
                      <Error error={brokerExistError.isActive} type="small" />
                    )}
                    <div className={style.buttonWrap}>
                      <div className={style.resetAdd}>
                        <CustomButton
                          text="Close"
                          type="btn-outline-primary"
                          size="btn-lg"
                          handleClick={() => handleCloseBroker(setValues)}
                        />
                        <button
                          className="btn-primary btn-lg"
                          type="submit"
                          disabled={
                            !isValid ||
                            !dirty ||
                            isSubmitting ||
                            brokerModalError ||
                            brokerExistError.isActive
                          }
                          style={{ width: "100%" }}
                          onClick={() => handleSubmitBroker(values, setValues)}
                        >
                          {id ? "Update" : "Add"}
                          {isSubmitting && (
                            <span
                              className="spinner-border spinner-border-sm ms-2 text-light"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Modal>
                </>
              );
            }}
          </Formik>
        </>
      )}
      {/* {blocker.state === 'blocked' ? (
        <div>
          <p>Are you sure you want to leave?</p>
          <button onClick={() => blocker.proceed()}>Proceed</button>
          <button onClick={() => blocker.reset()}>Cancel</button>
        </div>
      ) : null} */}
      {console.log((id && isDraft === "123") || (!id && !isDraft))}
      <WarningModal
        isActive={blocker.state === "blocked"}
        onClose={() => blocker.proceed()}
        onClick={() =>
          handleSubmit(
            copyValues,
            ` ${
              (id && isDraft === "123") || (!id && !isDraft)
                ? "Draft"
                : "Active"
            }`,
          )
        }
        title={"Draft Changes"}
      ></WarningModal>
      {/*This is Insurance Modal */}
      <Modal
        isActive={modalStateInsurance.isModalOpen}
        title={modalStateInsurance.modalTitle}
        onClose={handleCloseInsurance}
      >
        <Formik
          initialValues={{
            limit: "",
            currency: "",
            limit_type: "",
            policy_name: "",
            carrier_name: "",
            reporting_terms: "",
            start_date: "",
            end_date: "",
          }}
          validationSchema={Yup.object().shape({
            limit: Yup.number().required("Limit is required"),
            currency: Yup.string().required("Currency is required"),
            limit_type: Yup.string().required("Limit Type is required"),
            policy_name: Yup.string().required("Policy name is required"),
            carrier_name: Yup.string().required("Carrier name is required"),
            reporting_terms: Yup.string().required(
              "Reporting terms is required",
            ),
            start_date: Yup.string().required("Start date is required"),
            end_date: Yup.string().required("End Date is required"),
          })}
          enableReinitialize
          validateOnChange
          validateOnBlur
          key={Math.random()}
        >
          {({
            values,
            touched,
            errors,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            isValid,
            dirty,
            isSubmitting,
          }) => (
            <>
              <MyInput
                type="text"
                name="limit"
                customholder="Limit"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                InputHeight="small"
                width="100%"
              />
              <div className="mt-2">
                <MyDropdown
                  placeholder="Currency"
                  data={CurrencyData}
                  onChange={(option) => {
                    setFieldValue("currency", option);
                  }}
                  selectedValue={values?.currency}
                  width="100%"
                  isError={values?.currency}
                  touched={touched.currency}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("currency", true);
                  }}
                  errors={errors.currency}
                />
              </div>
              <div className="mt-2">
                <MyDropdown
                  placeholder="Limit Type"
                  data={LimitTypeData}
                  onChange={(option) => {
                    setFieldValue("limit_type", option);
                  }}
                  selectedValue={values?.limit_type}
                  width="100%"
                  isError={values?.limit_type}
                  touched={touched.limit_type}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("limit_type", true);
                  }}
                  errors={errors.limit_type}
                />
              </div>
              <div className="mt-2">
                <MyDropdown
                  placeholder="Policy Name"
                  data={PolicyNameData}
                  onChange={(option) => {
                    setFieldValue("policy_name", option);
                  }}
                  selectedValue={values?.policy_name}
                  width="100%"
                  isError={values?.policy_name}
                  touched={touched.policy_name}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("policy_name", true);
                  }}
                  errors={errors.policy_name}
                />
              </div>
              <div className="mt-2">
                <MyDropdown
                  placeholder="Carrier Name"
                  data={CarrierNameData}
                  onChange={(option) => {
                    setFieldValue("carrier_name", option);
                  }}
                  selectedValue={values?.carrier_name}
                  width="100%"
                  isError={values?.carrier_name}
                  touched={touched.carrier_name}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("carrier_name", true);
                  }}
                  errors={errors.carrier_name}
                />
              </div>
              <div className="mt-2">
                <MyDropdown
                  placeholder="Reporting Terms"
                  data={ReportingData}
                  onChange={(option) => {
                    setFieldValue("reporting_terms", option);
                  }}
                  selectedValue={values?.reporting_terms}
                  width="100%"
                  isError={values?.reporting_terms}
                  touched={touched.reporting_terms}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("reporting_terms", true);
                  }}
                  errors={errors.reporting_terms}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                <DateSelector
                  placeholder="Select Start Date"
                  onChange={async (option) => {
                    setFieldValue("start_date", option);
                  }}
                  selectedDate={values.start_date}
                  isError={values?.start_date}
                  touched={touched.start_date}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("start_date", true);
                  }}
                  errors={errors.start_date}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                <DateSelector
                  placeholder="Select End Date"
                  onChange={async (option) => {
                    setFieldValue("end_date", option);
                  }}
                  selectedDate={values.end_date}
                  isError={values?.end_date}
                  touched={touched.end_date}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("end_date", true);
                  }}
                  errors={errors.end_date}
                />
              </div>
              <div className={style.buttonWrap}>
                <div className={style.resetAdd}>
                  <CustomButton
                    text="Close"
                    type="btn-outline-primary"
                    size="btn-lg"
                    handleClick={() => handleCloseInsurance()}
                  />
                  <button
                    className="btn-primary btn-lg"
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    style={{ width: "100%" }}
                    onClick={() => handleSubmitInsurance(values)}
                  >
                    {id ? "Update" : "Add"}
                    {isSubmitting && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
        </Formik>
      </Modal>
      <Modal
        isActive={inusuranceItem.isActive}
        onClose={() =>
          setInsuranceItem({
            isActive: null,
            data: null,
          })
        }
        title={`${inusuranceItem?.data?.[0]?.Policy_Name} Details`}
      >
        {inusuranceItem.data &&
          inusuranceItem.data.map((item, index) => (
            <div key={index}>
              {Object.entries(item).map(([label, value]) => (
                <LabelDisplay
                  label={label.replace(/_/g, " ")}
                  value={value}
                  key={label}
                  modalStyles={true}
                />
              ))}
            </div>
          ))}
      </Modal>
    </>
  );
};

export default Basic;
