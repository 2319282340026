import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CustomButton from "../../../../../atoms/CustomButton";
import PageHeader from "../../../../../atoms/PageHeader";
import BackBtn from "../../../../../atoms/BackBtn";
import PageCard from "../../../../../atoms/PageCard";
import PermissionSelector from "./PermissionSelector";
import InputLayout from "../../../../../atoms/InputLayout";
import Error from "../../../../../atoms/Error";
import { useNavigate, useParams } from "react-router-dom";
import { postData, getData, patchData } from "../../../../../../services";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/components/toast";
import { useCookies } from "react-cookie";
import MyInput from "../../../../../atoms/MyInput";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import { Heading } from "../../../../../atoms/Heading";

const Add = ({ type = "add" }) => {
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "role/getRoleByID",
        token: cookies.t,
        params: { id: id },
      });
      setInitialValues(res);
    };

    if (type === "edit") {
      fetchData();
    }
  }, []);

  const modules = [
    { name: "Dashboard", identifier: "dashboard" },
    { name: "Funding", identifier: "funding" },
    { name: "Payment", identifier: "payment" },
    { name: "Monitoring", identifier: "profile-monitoring" },
    { name: "Notes", identifier: "notes" },
    { name: "Tools", identifier: "tools" },
    { name: "Counterparties", identifier: "counterparties" },
    { name: "Reports", identifier: "reports" },
    { name: "Onboarding", identifier: "onboarding" },
    { name: "Monitoring", identifier: "monitoring" },
    { name: "Fund", identifier: "fund" },
    { name: "Signers", identifier: "signers" },
    { name: "Brokers", identifier: "brokers" },
    { name: "Investors", identifier: "investors" },
    { name: "Insurance", identifier: "insurance" },
    { name: "Rates", identifier: "rates" },
    { name: "Pricing", identifier: "pricing" },
    { name: "Covenants", identifier: "covenants" },
    { name: "Reporting Requirements", identifier: "reporting-requirnments" },
    { name: "Role Group Management", identifier: "role-group-managenment" },
    { name: "Role Management", identifier: "role-management" },
    { name: "User Management", identifier: "user-management" },
  ];

  const initialData = {
    role_name: initialValues?.data?.role_name || "",
    data: initialValues?.data?.role_access || [],
  };

  const validationSchema = Yup.object().shape({
    role_name: Yup.string().required("Role is required"),
  });

  const handleSubmit = async (values) => {
    setIsSaving(true);

    try {
      const res = await postData({
        endpoint: "/role/create",
        token: cookies.t,
        data: { role_name: values.role_name, role_access: values.data },
      });
      if (res) {
        toast.success("Role Created Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occurred. Please try again later.", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    setIsSaving(true);

    try {
      const res = await patchData({
        endpoint: "role/update",
        token: cookies.t,
        params: { id: id },
        data: { role_name: values.role_name, role_access: values.data },
      });
      if (res) {
        toast.success("Role Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occurred. Please try again later.", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        resetForm,
      }) => (
        <>
          <Breadcrumb labels={["Role Management", "Add Roles"]} />
          <Heading className="addHeaderStyle">
            {type === "add" ? "Add Role" : "Edit Role"}
          </Heading>
          <div className="row mb-4 mt-3">
            <MyInput
              type="text"
              id="role_name"
              name="role_name"
              customholder="Enter Role"
              width="32%"
            />
          </div>

          <Heading className="h5 mb-3">Assign Permissions</Heading>
          <ParentComponent
            modules={modules}
            initialData={initialData}
            values={values}
            setFieldValue={setFieldValue}
          />

          <div className="d-flex w-100 justify-between align-items-center mt-3">
            <CustomButton
              text="Cancel"
              size="btn-lg"
              iconRequired={false}
              handleClick={() => {
                navigate(-1);
              }}
            />

            <div className="d-flex gap-3 mt-4">
              {/* <CustomButton
                text="Reset"
                iconRequired={false}
                handleClick={() => {
                  resetForm()
                }}
                type="btn-outline-primary"
              /> */}
              {type === "add" ? (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary "
                  buttonType="submit"
                  handleClick={() => handleSubmit(values)}
                  disabled={!isValid || !dirty || isSaving}
                >
                  Add
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              ) : (
                <CustomButton
                  iconRequired={false}
                  size="btn-lg"
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleEdit(values)}
                  disabled={!isValid || isSaving}
                >
                  Update
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const ParentComponent = ({ modules, initialData, values, setFieldValue }) => {
  const memoizedSelectedModules = useMemo(() => {
    return modules.map((module) => ({
      module,
      selectedModule:
        initialData?.data?.find((item) => item.module === module.name) || {},
    }));
  }, [initialData, modules]);

  const handlePermissionChange = useCallback(
    (permissions) => {
      const updatedPermissions = [...values.data];
      const index = updatedPermissions.findIndex(
        (item) => item.module === permissions.module,
      );
      if (index > -1) {
        updatedPermissions[index] = permissions;
      } else {
        updatedPermissions.push(permissions);
      }
      setFieldValue("data", updatedPermissions);
    },
    [values.data, setFieldValue],
  );

  // const handlePermissionChange = useCallback(
  //   (permissions) => {
  //     const updatedPermissions = [...values.data];

  //     // Find if the module exists in the `role_access`
  //     const index = updatedPermissions.findIndex(
  //       (item) => Object.keys(item)[0] === permissions.module,
  //     );

  //     // Get the existing permissions for the module, if available
  //     const existingPermissions =
  //       index > -1 ? updatedPermissions[index][permissions.module] : [];

  //     // Filter the permissions that are set to true (view, add/edit, etc.)
  //     const newPermissions = Object.keys(permissions)
  //       .filter((key) => permissions[key]) // only keep permissions that are true
  //       .map((key) => key); // use the actual permission names (e.g., view, add/edit)

  //     // Combine existing permissions with new permissions, ensuring no duplicates
  //     const updatedPermissionList = [
  //       ...new Set([...existingPermissions, ...newPermissions]),
  //     ];

  //     // Update or add the module's permissions
  //     if (index > -1) {
  //       updatedPermissions[index] = {
  //         [permissions.module]: updatedPermissionList,
  //       };
  //     } else {
  //       updatedPermissions.push({
  //         [permissions.module]: updatedPermissionList,
  //       });
  //     }

  //     // Set the updated permissions in the form state
  //     setFieldValue("data", updatedPermissions);
  //   },
  //   [values.data, setFieldValue],
  // );

  // const handlePermissionChange = useCallback(
  //   (permissions) => {
  //     const updatedPermissions = [...values.data];

  //     // Find if the module exists in the `role_access`
  //     const index = updatedPermissions.findIndex(
  //       (item) => Object.keys(item)[0] === permissions.module,
  //     );

  //     // Filter out the "module" key and only include valid permissions
  //     const newPermissions = Object.keys(permissions)
  //       .filter((key) => key !== "module" && permissions[key]) // exclude "module" and only keep permissions set to true
  //       .map((key) => key); // keep the permission name (e.g., "view", "add/edit")

  //     // Get existing permissions for the module if available
  //     const existingPermissions =
  //       index > -1 ? updatedPermissions[index][permissions.module] : [];

  //     // Combine the existing permissions with the new permissions, avoiding duplicates
  //     const updatedPermissionList = [
  //       ...new Set([...existingPermissions, ...newPermissions]),
  //     ];

  //     if (updatedPermissionList.length > 0) {
  //       // Update or add the module's permissions if they are not empty
  //       if (index > -1) {
  //         updatedPermissions[index] = {
  //           [permissions.module]: updatedPermissionList,
  //         };
  //       } else {
  //         updatedPermissions.push({
  //           [permissions.module]: updatedPermissionList,
  //         });
  //       }
  //     } else if (index > -1) {
  //       // Remove modules with empty permissions
  //       updatedPermissions.splice(index, 1);
  //     }

  //     // Set the updated permissions in the form state
  //     setFieldValue(
  //       "data",
  //       updatedPermissions.filter((item) => Object.values(item)[0].length > 0),
  //     );
  //   },
  //   [values.data, setFieldValue],
  // );

  console.log("values of data :", values.data);

  // Helper function to map is_create/is_edit/etc. to actual permission types
  const mapPermissionType = (key) => {
    switch (key) {
      case "is_create":
        return "add/edit";
      case "is_edit":
        return "add/edit";
      case "is_delete":
        return "delete";
      case "is_view":
        return "view";
      case "is_export":
        return "export";
      default:
        return "unknown";
    }
  };

  return (
    <div>
      <table className="permission-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Hide</th>
            <th>View</th>
            <th>Add/Edit</th>
            <th>Export</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {memoizedSelectedModules.map(({ module, selectedModule }) => (
            <PermissionSelector
              key={module.name}
              label={module.name}
              identifier={module.identifier}
              selected={selectedModule}
              onChange={handlePermissionChange}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Add;
